import Navbar from "../sections/Navbar";
import Footer from "../sections/Footer";
import useScrollToTopOnMount from "../../hooks/useScrollToTopOnMount";
import PageHeaderSection from "../sections/PageHeaderSection";
import careersImage from "../../assets/images/careers-image.jpg";
import './../../assets/sass/careers.scss'
import AccordionItem from "../components/AccordionItem";

export default function CareersPage() {
    useScrollToTopOnMount();

    return (
        <div id="careers-page">
            <Navbar active={'careers'}/>
            <PageHeaderSection title={'careers'}/>

            <section className="bg-accent-black text-white pb-5">
                <div className="container">
                    <div className="display-1 text-uppercase pt-1 pb-5">
                        WE ARE ALWAYS <br/>
                        LOOKING FOR
                    </div>

                    <div className="row">
                        <div className="col-lg-5 mb-3">
                            <div className="fs-1" style={{textAlign: 'justify'}}>
                                We are seeking ambitious
                                people who want to make a
                                difference, to progress and
                                learn. br
                                These positions provide
                                opportunities for those who
                                enjoy taking ownership of
                                their work and will suit
                                people who are organised,
                                rigorous in their approach
                                and are <br/>
                                ‘Big-Picture Thinkers’.
                            </div>
                            <div className="display-3 fw-normal mt-5">
                                IF YOU ARE ONE, <br/>
                                GET IN TOUCH: <br/>
                                <a className="text-white linear-bg-effect text-break fs-2 fw-light text-decoration-none"
                                   href="mailto:iwanttowork@gettowander.com">iwanttowork@gettowander.com</a>
                            </div>

                        </div>
                        <div className="col-lg-7 px-5 mb-3">
                            <img src={careersImage} alt="careers" style={{width: '100%'}}/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">

                            <div className="display-3 fw-bold text-black ">
                                OPEN POSITIONS
                            </div>
                            <div className="py-5 fs-2">
                                To apply, please click on the link <br/>
                                below each job description.
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="px-2">
                                <div
                                    id="accordion-careers"
                                    className="accordion accordion-flush border-top border-bottom border-black"
                                >
                                    <AccordionItem
                                        id="job-1"
                                        parentId='accordion-careers'
                                        header="Design Manager"
                                    >
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipisicing elit. Aperiam blanditiis commodi cupiditate dicta dolore
                                        doloremque earum esse, hic nesciunt nostrum nulla pariatur possimus
                                        quasi,
                                        quidem tempore, ullam vitae voluptate voluptatum.
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi
                                        consectetur corporis, doloremque doloribus esse explicabo facilis id
                                        provident quia sed? Animi explicabo fugiat harum hic libero odio
                                        possimus
                                        veritatis, voluptatem!
                                    </AccordionItem>

                                    <AccordionItem
                                        id="job-2"
                                        parentId='accordion-careers'
                                        header="Senior Designer"
                                    >
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ex hic
                                        mollitia
                                        officia sapiente voluptate? Dolorum esse, et harum itaque iure iusto
                                        laboriosam perspiciatis sed sint? Aliquam esse excepturi quis sed.
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Esse facilis
                                        iure
                                        laboriosam. Assumenda consequuntur culpa doloribus, explicabo facere
                                        fugiat,
                                        hic nostrum officia placeat praesentium recusandae sequi ullam. Fuga,
                                        ipsa
                                        natus.
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque commodi
                                        consequuntur explicabo iusto quis tempore, veritatis. Accusamus aut
                                        debitis
                                        eius explicabo facilis modi nobis odit, officia optio quasi, quos sed.
                                    </AccordionItem>

                                    <AccordionItem
                                        id="job-3"
                                        parentId='accordion-careers'
                                        header=" Intermediate (Midweight) Designer (Signage & Wayfinding)"
                                    >
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad blanditiis
                                        consectetur consequuntur distinctio dolorum eligendi impedit, iste
                                        itaque
                                        labore nam nisi odit optio, perferendis qui quibusdam quis quisquam quo
                                        tenetur?
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet atque
                                        cumque
                                        deleniti dolorem dolores eligendi fugit hic illo, iure maxime
                                        perspiciatis
                                        provident quam quibusdam, quis similique sit vitae voluptatibus
                                        voluptatum.
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam
                                        aliquid
                                        architecto atque dicta dignissimos, doloribus nemo nostrum, odit
                                        provident
                                        quaerat repellendus saepe sequi. Accusamus expedita minima obcaecati,
                                        officiis sapiente veniam?
                                    </AccordionItem>

                                    <AccordionItem
                                        id="job-4"
                                        parentId='accordion-careers'
                                        header="Intermediate (Midweight) Designer"
                                    >
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad blanditiis
                                        consectetur consequuntur distinctio dolorum eligendi impedit, iste
                                        itaque
                                        labore nam nisi odit optio, perferendis qui quibusdam quis quisquam quo
                                        tenetur?
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet atque
                                        cumque
                                        deleniti dolorem dolores eligendi fugit hic illo, iure maxime
                                        perspiciatis
                                        provident quam quibusdam, quis similique sit vitae voluptatibus
                                        voluptatum.
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam
                                        aliquid
                                        architecto atque dicta dignissimos, doloribus nemo nostrum, odit
                                        provident
                                        quaerat repellendus saepe sequi. Accusamus expedita minima obcaecati,
                                        officiis sapiente veniam?
                                    </AccordionItem>

                                    <AccordionItem
                                        id="job-5"
                                        parentId='accordion-careers'
                                        header="Junior Designer 2D"
                                    >
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad blanditiis
                                        consectetur consequuntur distinctio dolorum eligendi impedit, iste
                                        itaque
                                        labore nam nisi odit optio, perferendis qui quibusdam quis quisquam quo
                                        tenetur?
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet atque
                                        cumque
                                        deleniti dolorem dolores eligendi fugit hic illo, iure maxime
                                        perspiciatis
                                        provident quam quibusdam, quis similique sit vitae voluptatibus
                                        voluptatum.
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam
                                        aliquid
                                        architecto atque dicta dignissimos, doloribus nemo nostrum, odit
                                        provident
                                        quaerat repellendus saepe sequi. Accusamus expedita minima obcaecati,
                                        officiis sapiente veniam?
                                    </AccordionItem>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </div>
    );
}