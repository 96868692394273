import useScrollToTopOnMount from "../../hooks/useScrollToTopOnMount";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Navbar from "../sections/Navbar";
import Footer from "../sections/Footer";
import PageHeaderSection from "../sections/PageHeaderSection";
import ProjectSection from "../sections/ProjectSection";

import LakeyardVideo from "../../assets/videos/Lakeyard.mp4";
import LakeyardPoser from "../../assets/videos/Lakeyard-poster.jpg";
import LightHouseVideo from "../../assets/videos/Lighthouse.mp4";
import LightHousePoster from "../../assets/videos/Lighthouse-poster.jpg";
import NorthedVideo from "../../assets/videos/Northed.mp4";
import NorthedPoster from "../../assets/videos/Northed-poster.jpg";

export default function ProjectPage() {
    useScrollToTopOnMount();

    const [project, setProject] = useState(null);
    const params = useParams();
    const navigate = useNavigate();


    useEffect(() => {
        const availableProjects = ['Lakeyard', 'Lighthouse', 'Northed'];
        if (availableProjects.includes(params.project))
            setProject(() => params.project)
        else
            navigate('/projects');
    }, [navigate, params.project]);

    return (
        project && (
            <div id='project-page'>
                <Navbar active={'projects'}/>
                <PageHeaderSection title={project}/>

                {
                    (project === 'Lakeyard') &&
                    <ProjectSection
                        heading='SKETCHING A VISION, CONSTRUCTING A DREAM, LAUNCHING A GAMECHANGER.'
                        video={LakeyardVideo}
                        poster={LakeyardPoser}
                    >
                        <div className="row gx-5 fs-5 text-white">
                            <div className="col-lg-6">
                                <div className="px-3" style={{textAlign: 'justify'}}>
                                    Sketching a vision, constructing a dream, launching a game changer. The soulful
                                    destination that transformed the North Coast commercial scene, and Egypt at large.
                                    Lakeyard is a place that has a whole generation emotionally connected to. A project
                                    that became a category owner in the commercial world by holding its ground against
                                    emerging competition over the years. By being the first and pioneers of the fusion
                                    of F&B, retail, entertainment, and the opportunity of a shopping experience at one
                                    destination, formulated a groundbreaking concept that is the first of its kind in
                                    the area. Over the years, it has become the birthplace of successful local brands.
                                    Turning it into a future-forward, strategically leased hub for everything-local.
                                    Blending a tenant mix that offers a cohesive experience for families and friends.
                                    For the fitness enthusiasts, we had included BeFit, which meant changing people’s
                                    behaviour in the North Coast at the time. We decided to go beyond local borders for
                                    kids entertainment, and licensed Cartoon Network Town play area. A unique move that
                                    landed us the international badge of brands.
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="px-3" style={{textAlign: 'justify'}}>
                                    What started out as trucks and containers grouped together next to a large water
                                    feature, is now being referred to as a vibrant city of its own. Loud, colourful,
                                    artistic, and interactive. A space that transcends its people to a whole other
                                    world. Mimicking a city true to its identity, we designed the latin American houses
                                    to scale. Forced but subtle, we created a strategic traffic driving journey by
                                    directing visitors towards retail. The intentional design forces traffic through
                                    alleys, casual dining experiences, upscale food courts, and open shared seating
                                    areas. Allocation of art was no coincidence, they were placed at certain turns and
                                    angles. <br/>
                                    We utilised another aspect for driving traffic, that ended up going viral
                                    and taking zero marketing efforts from our side; the first Instagrammable murals. An
                                    aesthetic addition that levelled up the interactivity and engagement of the people
                                    with the place. Capturing the hearts of the community. By following a
                                    something-for-everyone philosophy, we designed an experience for every member of the
                                    family. Crafting endless journeys for one to explore. Lakeyard, a testament to a
                                    recipe for success that defied all conventional norms. A project with sales volume
                                    that grew year-over-year, created demand in tenants to be in be part of the vibrant
                                    community, and a love for a brand that disrupted the commercial market.
                                </div>
                            </div>
                        </div>
                    </ProjectSection>
                }



                {
                    (project === 'Lighthouse') &&
                    <ProjectSection
                        heading={<>A HOLISTICALLY REWARDING <br/> HANGOUT.</>}
                        video={LightHouseVideo}
                        poster={LightHousePoster}
                    >
                        <div className="row gx-5 fs-5 text-white">
                            <div className="col-lg-6">
                                <div className="px-3" style={{textAlign: 'justify'}}>
                                    A destination that enlightens the young, engages the old, and entertains all. The Lighthouse, a place designed to guide the lost through a journey that unfolds into zones of F&B, retail, and kids edutainment. To develop this experience, we thought humanly, and created commercially. Our work methodology unites between the developer’s business objectives, and the creation of a humanized commercial experience. We took a deep dive into understanding Mountain View’s Happiness platform, and objectives of serving its wide community of residents, gaining commercial equity, and attracting footfall. We layout vendors, brands, workshops, and map out a connective journey that feeds into one another.
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="px-3" style={{textAlign: 'justify'}}>
                                    When studying artisanal design directions to follow one that matches the project, we visualised a walk through intimate Greek alleys with a sweeping white color that powerfully tranquillises the mind, and welcomes the soul. An architectural approach that cuts proximity between passerbys and shops. Lighthouse, as a commercial destination, is a concept that mirrors Mountain View’s principles of offering better development for its community. True to the core, along the experiential journey, there is a collaborative space with WellSpring that serves this mission by curating camps and workshops that exposes kids to different aspects of life and work. <br/> A world that addresses all realms. A place where kids can belong
                                </div>
                            </div>
                        </div>
                    </ProjectSection>
                }


                {
                    (project === 'Northed') &&
                    <ProjectSection
                        heading='A STATE OF FRESHNESS.'
                        video={NorthedVideo}
                        poster={NorthedPoster}
                    >
                        <div className="row gx-5 fs-5 text-white">
                            <div className="col-lg-6">
                                <div className="px-3" style={{textAlign: 'justify'}}>
                                    Commercial destinations could be a purposeful move by a developer. Northed is a solid example. A project that strategically came to life to be named a game changer. Zahra, where Northed lie by the border, needed to solidify its presence in the market and make some noise for the buyers. Our queue to jump in. The conceptual philosophy we followed had a differentiated factor from other destinations we created. With a small plot of land as our canvas to build this destination, we went with architectural levels and elevations. A design justification to achieve maximum visibility for the tenants, utilise the area optimally, and for visitors to flow through an engaging journey. <br/> Northed feeds on short trips, and on-the-go visits. This reflected highly on the choice of outlets; an all F&B destination with no retail. We landed the project high traffic-driving F&B tenants, with two having drive-thru as an additional point of sale. This is a breakthrough as this kind of accessibility is limitedly found in the North Coast for non fast food outlets. An edge that rippled on to reflect in revenues and traffic. The destination’s brand is a calling for the Gen Z. Its design language makes it visually appealing and a photo opportunity attraction. It radiates with a summer peach tone that makes it pop, and catches the eyes of those who drive by. The tropical Mexican vibe distinguishes it from the surrounding context, while yet blending in.
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="px-3" style={{textAlign: 'justify'}}>
                                    Labelled as the new hangout spot for the youth, Northed is a tale that tells a story of success. Delivering on promises for this project to achieve enhanced brand positioning, market share from nearby offerings, and year-over-year growth. A destination with peach contours amidst flat sandy lands, that caters to the youth, and is extended to families as well.
                                </div>
                            </div>
                        </div>
                    </ProjectSection>
                }



                <Footer/>
            </div>
        )
    );
}