import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import {useCallback, useRef} from "react";
import lighthouseImg from '../../assets/images/slide-lighthouse.jpg';
import lakeyardImg from '../../assets/images/slide-lake-yard.jpg';
import northedImg from '../../assets/images/slide-northed.jpg';
import {Link} from "react-router-dom";
import {Autoplay} from "swiper/modules";

export default function ProjectsSection() {
    const sliderRef = useRef(null);

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);

    return (
        <section id="projects">
            <div className="title">
                <div className="container py-5 lh-1">
                    <div className="text-uppercase fs-3 fw-light">WANDER IN</div>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="display-5">OUR PROJECTS</div>
                        <div className="arrows d-flex">
                            <div role="button" className="me-2" onClick={handleNext}>
                                <svg width="70" height="70" viewBox="0 0 70 70" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M35 69C53.7777 69 69 53.7777 69 35C69 16.2223 53.7777 1 35 1C16.2223 1 1 16.2223 1 35C1 53.7777 16.2223 69 35 69Z"
                                        stroke="#1A1A1A" strokeWidth="2"/>
                                    <path
                                        d="M34.101 22.695L35.172 23.766L25.793 33.903H49.135V36.309H25.984L35.172 46.233L34.101 47.304L22.964 36.956L20.864 34.995L22.964 33.043L34.101 22.695Z"
                                        fill="#1A1A1A"/>
                                </svg>
                            </div>
                            <div role="button" className="" onClick={handlePrev}>
                                <svg width="70" height="70" viewBox="0 0 70 70" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M35 69C53.7777 69 69 53.7777 69 35C69 16.2223 53.7777 1 35 1C16.2223 1 1 16.2223 1 35C1 53.7777 16.2223 69 35 69Z"
                                        stroke="#1A1A1A" strokeWidth="2"/>
                                    <path
                                        d="M35.899 22.695L34.828 23.766L44.206 33.904H20.865V36.31H44.016L34.828 46.234L35.899 47.305L47.031 36.956L49.131 34.999L47.031 33.047L35.899 22.695Z"
                                        fill="#1A1A1A"/>
                                </svg>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Swiper
                ref={sliderRef}
                slidesPerView={'auto'}
                centeredSlides={true}
                spaceBetween={30}
                loop={true}
                modules={[Autoplay]}
                className="projects-slider"
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                    reverseDirection: true,
                }}
            >
                <SwiperSlide>
                    <div className="slider-content">
                        <div className="slide-img" style={{backgroundImage: `url(${lighthouseImg})`}}/>
                        <div className="details">
                            <div className="fs-4 lh-1">DESIGN & BUILD</div>
                            <div className="fs-1">THE LIGHT HOUSE</div>
                            <Link to={'/projects/Lighthouse'}
                                  className="text-decoration-none text-accent fw-light"
                            >
                                <span className="fs-5 me-3">
                                    VIEW DETAILS
                                </span>

                                <svg xmlns="http://www.w3.org/2000/svg"
                                     width="15.591"
                                     height="13.572"
                                     viewBox="0 0 15.591 13.572">
                                    <path
                                        d="M8.291,0,7.7.591l5.172,5.591H0V7.508H12.768L7.7,12.981l.591.591,6.139-5.707h0l1.16-1.08L14.433,5.71Z"
                                        transform="translate(0 0)"
                                    />
                                </svg>
                            </Link>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-content">
                        <div className="slide-img" style={{backgroundImage: `url(${lakeyardImg})`}}/>
                        <div className="details">
                            <div className="fs-4 lh-1">COMMERCIAL DEVELOPMENT</div>
                            <div className="fs-1">LAKEYARD</div>
                            <Link to={'/projects/Lakeyard'}
                                  className="text-decoration-none text-accent fw-light"
                            >
                                <span className="fs-5 me-3">
                                    VIEW DETAILS
                                </span>

                                <svg xmlns="http://www.w3.org/2000/svg"
                                     width="15.591"
                                     height="13.572"
                                     viewBox="0 0 15.591 13.572">
                                    <path
                                        d="M8.291,0,7.7.591l5.172,5.591H0V7.508H12.768L7.7,12.981l.591.591,6.139-5.707h0l1.16-1.08L14.433,5.71Z"
                                        transform="translate(0 0)"
                                    />
                                </svg>
                            </Link>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-content">
                        <div className="slide-img" style={{backgroundImage: `url(${northedImg})`}}/>
                        <div className="details">
                            <div className="fs-4 lh-1">DEVELOPMEMT STRATEGY</div>
                            <div className="fs-1">NORTHED</div>
                            <Link to={'/projects/Northed'}
                                  className="text-decoration-none text-accent fw-light"
                            >
                                <span className="fs-5 me-3">
                                    VIEW DETAILS
                                </span>

                                <svg xmlns="http://www.w3.org/2000/svg"
                                     width="15.591"
                                     height="13.572"
                                     viewBox="0 0 15.591 13.572">
                                    <path
                                        d="M8.291,0,7.7.591l5.172,5.591H0V7.508H12.768L7.7,12.981l.591.591,6.139-5.707h0l1.16-1.08L14.433,5.71Z"
                                        transform="translate(0 0)"
                                    />
                                </svg>
                            </Link>
                        </div>

                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-content">
                        <div className="slide-img" style={{backgroundImage: `url(${lighthouseImg})`}}/>
                        <div className="details">
                            <div className="fs-4 lh-1">DESIGN & BUILD</div>
                            <div className="fs-1">THE LIGHT HOUSE</div>
                            <Link to={'/projects/Lighthouse'}
                                  className="text-decoration-none text-accent fw-light"
                            >
                                <span className="fs-5 me-3">
                                    VIEW DETAILS
                                </span>

                                <svg xmlns="http://www.w3.org/2000/svg"
                                     width="15.591"
                                     height="13.572"
                                     viewBox="0 0 15.591 13.572">
                                    <path
                                        d="M8.291,0,7.7.591l5.172,5.591H0V7.508H12.768L7.7,12.981l.591.591,6.139-5.707h0l1.16-1.08L14.433,5.71Z"
                                        transform="translate(0 0)"
                                    />
                                </svg>
                            </Link>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-content">
                        <div className="slide-img" style={{backgroundImage: `url(${lakeyardImg})`}}/>
                        <div className="details">
                            <div className="fs-4 lh-1">COMMERCIAL DEVELOPMENT</div>
                            <div className="fs-1">LAKEYARD</div>
                            <Link to={'/projects/Lakeyard'}
                                  className="text-decoration-none text-accent fw-light"
                            >
                                <span className="fs-5 me-3">
                                    VIEW DETAILS
                                </span>

                                <svg xmlns="http://www.w3.org/2000/svg"
                                     width="15.591"
                                     height="13.572"
                                     viewBox="0 0 15.591 13.572">
                                    <path
                                        d="M8.291,0,7.7.591l5.172,5.591H0V7.508H12.768L7.7,12.981l.591.591,6.139-5.707h0l1.16-1.08L14.433,5.71Z"
                                        transform="translate(0 0)"
                                    />
                                </svg>
                            </Link>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-content">
                        <div className="slide-img" style={{backgroundImage: `url(${northedImg})`}}/>
                        <div className="details">
                            <div className="fs-4 lh-1">DEVELOPMEMT STRATEGY</div>
                            <div className="fs-1">NORTHED</div>
                            <Link to={'/projects/Northed'}
                                  className="text-decoration-none text-accent fw-light"
                            >
                                <span className="fs-5 me-3">
                                    VIEW DETAILS
                                </span>

                                <svg xmlns="http://www.w3.org/2000/svg"
                                     width="15.591"
                                     height="13.572"
                                     viewBox="0 0 15.591 13.572">
                                    <path
                                        d="M8.291,0,7.7.591l5.172,5.591H0V7.508H12.768L7.7,12.981l.591.591,6.139-5.707h0l1.16-1.08L14.433,5.71Z"
                                        transform="translate(0 0)"
                                    />
                                </svg>
                            </Link>
                        </div>

                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-content">
                        <div className="slide-img" style={{backgroundImage: `url(${lighthouseImg})`}}/>
                        <div className="details">
                            <div className="fs-4 lh-1">DESIGN & BUILD</div>
                            <div className="fs-1">THE LIGHT HOUSE</div>
                            <Link to={'/projects/Lighthouse'}
                                  className="text-decoration-none text-accent fw-light"
                            >
                                <span className="fs-5 me-3">
                                    VIEW DETAILS
                                </span>

                                <svg xmlns="http://www.w3.org/2000/svg"
                                     width="15.591"
                                     height="13.572"
                                     viewBox="0 0 15.591 13.572">
                                    <path
                                        d="M8.291,0,7.7.591l5.172,5.591H0V7.508H12.768L7.7,12.981l.591.591,6.139-5.707h0l1.16-1.08L14.433,5.71Z"
                                        transform="translate(0 0)"
                                    />
                                </svg>
                            </Link>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-content">
                        <div className="slide-img" style={{backgroundImage: `url(${lakeyardImg})`}}/>
                        <div className="details">
                            <div className="fs-4 lh-1">COMMERCIAL DEVELOPMENT</div>
                            <div className="fs-1">LAKEYARD</div>
                            <Link to={'/projects/Lakeyard'}
                                  className="text-decoration-none text-accent fw-light"
                            >
                                <span className="fs-5 me-3">
                                    VIEW DETAILS
                                </span>

                                <svg xmlns="http://www.w3.org/2000/svg"
                                     width="15.591"
                                     height="13.572"
                                     viewBox="0 0 15.591 13.572">
                                    <path
                                        d="M8.291,0,7.7.591l5.172,5.591H0V7.508H12.768L7.7,12.981l.591.591,6.139-5.707h0l1.16-1.08L14.433,5.71Z"
                                        transform="translate(0 0)"
                                    />
                                </svg>
                            </Link>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-content">
                        <div className="slide-img" style={{backgroundImage: `url(${northedImg})`}}/>
                        <div className="details">
                            <div className="fs-4 lh-1">DEVELOPMEMT STRATEGY</div>
                            <div className="fs-1">NORTHED</div>
                            <Link to={'/projects/Northed'}
                                  className="text-decoration-none text-accent fw-light"
                            >
                                <span className="fs-5 me-3">
                                    VIEW DETAILS
                                </span>

                                <svg xmlns="http://www.w3.org/2000/svg"
                                     width="15.591"
                                     height="13.572"
                                     viewBox="0 0 15.591 13.572">
                                    <path
                                        d="M8.291,0,7.7.591l5.172,5.591H0V7.508H12.768L7.7,12.981l.591.591,6.139-5.707h0l1.16-1.08L14.433,5.71Z"
                                        transform="translate(0 0)"
                                    />
                                </svg>
                            </Link>
                        </div>

                    </div>
                </SwiperSlide>
             </Swiper>
        </section>
    );
}