import Navbar from "../sections/Navbar";
import Footer from "../sections/Footer";
import PageHeaderSection from "../sections/PageHeaderSection";
import useScrollToTopOnMount from "../../hooks/useScrollToTopOnMount";
import '../../assets/sass/approache-page.scss';

export default function ApproachPage() {
    useScrollToTopOnMount();


    return (
        <div id="approach-page">

            <Navbar active={'approach'}/>

            <PageHeaderSection title={'OUR APPROACH'}/>

            <section className="section__human-centric">
                <div className="img-bg"/>
                <div className="content position-relative text-accent-white">
                    <div className="container">
                        <div className="head-top fw-normal text-end lh-1">
                            <span className='head-sm'>STRIVING TO</span>
                            <p className="fs-5">
                                BUILD UNITY & <br/>
                                CONNECT <br/>
                                COMMUNITIES
                            </p>
                        </div>

                        <div className="row flex-row-reverse">
                            <div className="col-lg-7">
                                <div className="text-block">
                                    <div className="fs-3 lh-1">WANDER IS</div>
                                    <div className="display-5 lh-1 mb-3">HUMAN CENTRIC</div>
                                    <p className="fs-5">We speak people, we speak places. Our commercial developments
                                        are constructed to be storytellers of concrete tales and humanized experiences.
                                        Believing in a commercial world that revolves around the humans of a space.
                                        Humancentrism is our design language, and destinations’ core guide. Backed by
                                        people-driven science, we transform a plot of land into a commercial hangout
                                        destination. We develop, create, build, and manage soulful destinations that
                                        speak to their designated humans.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section__experiential">
                <div className="img-bg"/>
                <div className="content position-relative text-accent-white">
                    <div className="container">
                        <div className="head-top lh-1 fw-normal">
                            <span className='head-sm'>A BRAND THAT</span>
                            <p className="fs-5">
                                UNDERSTANDS <br/>
                                THE HEARTBEAT <br/>
                                OF ITS <br/>
                                COMMUNITIES
                            </p>
                        </div>

                        <div className="row">
                            <div className="col-lg-7">
                                <div className="text-block">
                                    <div className="fs-3 lh-1">WANDER IS</div>
                                    <div className="display-5 lh-1 mb-3">EXPERIENTIAL</div>
                                    <p className="fs-5">We speak people, we speak places. Our commercial developments
                                        are constructed to be storytellers of concrete tales and humanized experiences.
                                        Believing in a commercial world that revolves around the humans of a space.
                                        Humancentrism is our design language, and destinations’ core guide. Backed by
                                        people-driven science, we transform a plot of land into a commercial hangout
                                        destination. We develop, create, build, and manage soulful destinations that
                                        speak to their designated humans.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section__future-forward">
                <div className="img-bg"/>
                <div className="content position-relative text-accent-white">
                    <div className="container">
                        <div className="head-top fw-normal text-end lh-1">
                            <span className='head-sm'>STRIVING TO</span>
                            <p className="fs-5">
                                BUILD UNITY & <br/>
                                CONNECT <br/>
                                COMMUNITIES
                            </p>
                        </div>

                        <div className="row flex-row-reverse">
                            <div className="col-lg-7">
                                <div className="text-block">
                                    <div className="fs-3 lh-1">WANDER IS</div>
                                    <div className="display-5 lh-1 mb-3">FUTURE FORWARD</div>
                                    <p className="fs-5">We speak people, we speak places. Our commercial developments
                                        are constructed to be storytellers of concrete tales and humanized experiences.
                                        Believing in a commercial world that revolves around the humans of a space.
                                        Humancentrism is our design language, and destinations’ core guide. Backed by
                                        people-driven science, we transform a plot of land into a commercial hangout
                                        destination. We develop, create, build, and manage soulful destinations that
                                        speak to their designated humans.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section__collaborative">
                <div className="img-bg"/>
                <div className="content position-relative text-accent-white">
                    <div className="container">
                        <div className="head-top lh-1 fw-normal">
                            <span className='head-sm'>A BRAND THAT</span>
                            <p className="fs-5">
                                UNDERSTANDS <br/>
                                THE HEARTBEAT <br/>
                                OF ITS <br/>
                                COMMUNITIES
                            </p>
                        </div>

                        <div className="row">
                            <div className="col-lg-7">
                                <div className="text-block">
                                    <div className="fs-3 lh-1">WANDER IS</div>
                                    <div className="display-5 lh-1 mb-3">COLLABORATIVE</div>
                                    <p className="fs-5">When we talk business, our uniquely soulful destinations are
                                        intertwined with the instrumental value that lies in our collaborative model;
                                        the power of one+. We find strength in partnering with different communities and
                                        entities, purposefully, to achieve and retain leadership in the commercial
                                        development market.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section__design-philosophy">
                <div className="img-bg"/>
                <div className="content position-relative text-accent-white">
                    <div className="container">
                        <div className="row flex-row-reverse">
                            <div className="col-lg-7">
                                <div className="text-block">
                                    <div className="fs-3 lh-1">WANDER IS</div>
                                    <div className="display-5 lh-1 mb-3">DESIGN PHILOSOPHY</div>
                                    <p className="fs-5 mb-3">Here is a human urge to exist somewhere where it makes
                                        sense. This is where the essence of our philosophy lies. Getting the lost
                                        wandering souls, found. Connecting souls of people to souls of places. As the
                                        human being is our design inspirational muse, our architectural language is a
                                        uniting force of the commonality of humans alike.
                                    </p>
                                    <p className="fs-5">Crafting experiential destinations for collectives of people. They wander through a journey mapped out for them. When arriving at one, each incomer find themselves belonging to a place that knows their soul. Designed to translate their emotions, activities, and interests into spaces. The destinations tell stories through narrators; the pillars of the space. So that we guide the humans to the simple pleasures of life, from getting a cup of coffee, to running errands, & end up finding familiar faces.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </div>
    );
}