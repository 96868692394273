export default function WhoWeAreSection() {
    return (
        <section className="bg-accent-black text-accent-white">
            <div className="py-5">
                <div className="container text-center py-5">
                    <h3 className='fw-normal fs-3 text-uppercase'>WHO ARE WE?</h3>
                    <p className="fs-4">
                        We are a Commercial Developer that creates commercial destinations for various purposes.
                        Covering
                        Shopping, Work, Entertainment, Learning, Culture and Hospitality for non-commercial specialized
                        developers In Egypt and the region through an Integrated services of Development Strategy,
                        Design &
                        Build and Property Management
                    </p>
                </div>
            </div>
        </section>
    );
}