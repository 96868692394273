class _Registry {
    constructor() {
        _Registry.prototype._store = {};
    }

    set(key, value){
        const newData = {};
        newData[key] = value;
        _Registry.prototype._store = { ..._Registry.prototype._store, ...newData }
    }

    get(key, defaultValue=null) {
        if (_Registry.prototype._store.hasOwnProperty(key))
            return _Registry.prototype._store[key];

        return defaultValue;
    }

    /**
     * 
     * @param {string} storeKey key of state
     * @param {callback} action state dispatch callback function
     * @param {string} stateKey 
     */
    dispatchState(storeKey, action, stateKey='setState'){
        const dispatchSetState = (this.get(storeKey) ?? {})[stateKey];
        !dispatchSetState && console.log('[dispatchSetState] not found for these keys');
        dispatchSetState && dispatchSetState(action);
    }

}

export const Registry = new _Registry();