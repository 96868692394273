export default function Person({id, image, name, position = '', children}) {

    return (
        <div className="person-offcanvas">
            <div
                className="person"
                role="button"
                data-bs-toggle="offcanvas"
                data-bs-target={`#${id}`}
            >
                <div className="avatar">
                    <img src={image} alt={name}/>
                </div>
                <div className="">
                    <div className="name fw-bold fs-3 text-capitalize">{name}</div>
                    <div className="job-position">{position}</div>
                </div>
            </div>

            <div
                className="offcanvas offcanvas-end"
                id={id}
            >
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title text-capitalize">
                        {name}
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    ></button>
                </div>
                <div className="offcanvas-body">
                    <div className="image">
                        <img className="w-100" src={image} alt={name}/>
                    </div>
                    <div className="py-3">
                        {children}
                    </div>
                </div>
            </div>

        </div>
    );
}