import Navbar from "../sections/Navbar";
import Footer from "../sections/Footer";
import useScrollToTopOnMount from "../../hooks/useScrollToTopOnMount";
import '../../assets/sass/projects.scss';
import ProjectsSection from "../sections/ProjectsSection";


export default function ProjectsPage() {
    useScrollToTopOnMount();

    return (
        <div id="projects-page">
            <Navbar active={'projects'}/>
            <ProjectsSection/>
            <Footer/>
        </div>
    );
}