import WanderLogo from "../components/WanderLogo";
import './../../assets/sass/footer.scss';

export default function Footer() {


    return (
        <footer className="footer py-5 shadow-lg">
            <button
                className="bg-white rounded-circle shadow-lg"
                id="go-top"
                onClick={
                    () => {
                        window.scrollTo({top: 0, behavior: 'smooth'});
                    }
                }
            >
                <svg
                    viewBox="0 0 36 36"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="xMidYMid meet"
                >
                    <path
                        d="M25.711 10.867L18.779.652c-.602-.885-1.558-.867-2.127.037l-6.39 10.141c-.569.904-.181 1.644.865 1.644H13V16a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-3.525h1.898c1.047 0 1.414-.723.813-1.608zM3.651 23.575H1.438c-.975 0-1.381-.712-1.381-1.401c0-.71.508-1.399 1.381-1.399h7.469c.874 0 1.381.689 1.381 1.399c0 .69-.406 1.401-1.381 1.401H6.696v10.189c0 1.016-.649 1.584-1.522 1.584s-1.522-.568-1.522-1.584V23.575zM10.396 28c0-4.222 2.841-7.471 6.982-7.471c4.079 0 6.983 3.351 6.983 7.471c0 4.201-2.821 7.471-6.983 7.471c-4.121 0-6.982-3.27-6.982-7.471zm10.798 0c0-2.456-1.279-4.67-3.816-4.67s-3.816 2.214-3.816 4.67c0 2.476 1.239 4.668 3.816 4.668c2.578 0 3.816-2.192 3.816-4.668zm4.433-5.644c0-.954.569-1.582 1.585-1.582h3.591c2.985 0 5.197 1.947 5.197 4.851c0 2.963-2.293 4.811-5.074 4.811h-2.253v3.329c0 1.016-.649 1.584-1.521 1.584c-.874 0-1.524-.568-1.524-1.584V22.356zm3.046 5.4h2.071c1.277 0 2.089-.934 2.089-2.151c0-1.219-.812-2.152-2.089-2.152h-2.071v4.303z"></path>
                </svg>
            </button>
            <div className="container">
                <div className="row text-center text-lg-start">
                    <div className="col-lg-4 mb-5 mb-lg-0 logo-wrapper ">
                        <div className="d-inline-block">
                            <div className="wander text-center">
                                <WanderLogo/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-5 mb-lg-0">
                        <h2 className="text-capitalize text-accent fw-normal fs-3">Follow us</h2>
                        <ul className="list-unstyled d-flex social-links justify-content-center justify-content-lg-start">
                            <li className="py-1">
                                <a href="https://www.instagram.com/gettowander/" target="_blank" rel="noreferrer"
                                   className="text-decoration-none color-black"><i
                                    className="ri-instagram-line"></i></a>
                            </li>
                            <li className="py-1">
                                <a href="https://www.facebook.com/profile.php?id=61555325387876" target="_blank"
                                   rel="noreferrer"
                                   className="text-decoration-none color-black"><i
                                    className="ri-facebook-fill"></i></a>
                            </li>
                            <li className="py-1">
                                <a href="https://www.linkedin.com/company/wander-development/" target="_blank"
                                   rel="noreferrer"
                                   className="text-decoration-none color-black"><i
                                    className="ri-linkedin-fill"></i></a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-4">
                        <h2 className="text-capitalize text-accent fw-normal fs-3">CONTACT US</h2>
                        <ul className="list-unstyled">
                            <li className="py-1 fs-5">
                                <a href="mailto:hello@gettowander.com" target="_blank" rel="noreferrer"
                                   className="text-decoration-none linear-bg-effect text-accent">hello@gettowander.com</a>
                            </li>
                            <li className="py-1 fs-5">
                                <a href="mailto:iwanttowork@gettowander.com" target="_blank" rel="noreferrer"
                                   className="text-decoration-none linear-bg-effect text-accent">iwanttowork@gettowander.com</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}