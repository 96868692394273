import {Registry} from "../../classes/Registry";
import LakeyardVideo from "../../assets/videos/Lakeyard.mp4";
import LakeyardPoster from "../../assets/videos/Lakeyard-poster.jpg";
import LightHouseVideo from "../../assets/videos/Lighthouse.mp4";
import LightHousePoster from "../../assets/videos/Lighthouse-poster.jpg";
import NorthedVideo from "../../assets/videos/Northed.mp4";
import NorthedPoser from "../../assets/videos/Northed-poster.jpg";
import {Link} from "react-router-dom";
import '../../assets/sass/destinations.scss';
import VideoDetailsLink from "../components/VideoDetailsLink";
import {togglePlayPauseHandler, videoToggleMuteHandler} from "../../global/global";
import VideoPlayIconSvg from "../components/VideoPlayIconSvg";

export default function DestinationsSection() {


    return (
        <section className="our-destinations">

            <div className="display-5 text-center text-uppercase fw-normal text-accent py-3">
                our destinations
            </div>

            <div className="container">
                <div className="row gx-0 mb-3">
                    <div className="col-lg-12">
                        <div className="video-outer">
                            <div className="video position-relative video__play">
                                <i
                                    onClick={videoToggleMuteHandler}
                                    className="position-absolute top-0 left start-0 ri-volume-mute-line px-1 d-inline-block bg-black text-white z-1"
                                    role="button"
                                />
                                <video
                                    onClick={togglePlayPauseHandler}
                                    className="w-100"
                                    src={LakeyardVideo}
                                    poster={LakeyardPoster}
                                    autoPlay={true}
                                    muted={true}
                                    controls={false}
                                    loop={true}
                                />
                                <VideoPlayIconSvg/>
                            </div>
                            <VideoDetailsLink name={'LAKEYARD'} linkPath={'/projects/Lakeyard'}/>
                        </div>
                    </div>
                </div>

                <div className="row gx-3">
                    <div className="col-lg-6">
                        <div className="video-outer">
                            <div className="video position-relative">
                                <i
                                    onClick={videoToggleMuteHandler}
                                    className="position-absolute top-0 left start-0 ri-volume-mute-line px-1 d-inline-block bg-black text-white z-1"
                                    role="button"
                                />
                                <video
                                    onClick={togglePlayPauseHandler}
                                    className="w-100"
                                    src={LightHouseVideo}
                                    poster={LightHousePoster}
                                    // autoPlay={true}
                                    muted={true}
                                    controls={false}
                                    loop={true}
                                />
                                <VideoPlayIconSvg/>
                            </div>
                            <VideoDetailsLink name={'THE LIGHT HOUSE'} linkPath={'/projects/Lighthouse'}/>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="video-outer">
                            <div className="video position-relative">
                                <i
                                    onClick={videoToggleMuteHandler}
                                    className="position-absolute top-0 left start-0 ri-volume-mute-line px-1 d-inline-block bg-black text-white z-1"
                                    role="button"
                                />
                                <video
                                    onClick={togglePlayPauseHandler}
                                    className="w-100"
                                    src={NorthedVideo}
                                    poster={NorthedPoser}
                                    // autoPlay={true}
                                    muted={true}
                                    controls={false}
                                    loop={true}
                                />
                                <VideoPlayIconSvg/>
                            </div>
                            <VideoDetailsLink name={'NORTHED'} linkPath={'/projects/Northed'}/>
                        </div>
                    </div>
                </div>

                <div className="p-5 text-center">
                    <Link
                        to={'/projects'}
                        className="text-decoration-none fw-normal px-4 btn btn-outline-dark rounded-0 text-uppercase btn-lg py-3 bg-accent-black text-accent-white"
                    >
                        view all projects
                    </Link>
                </div>
            </div>
        </section>
    );
}